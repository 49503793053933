/* Styles for hiding the container initially */
.d-none {
  display: none;
}

/* Styles for displaying the container during printing */
@media print {
  .print-block {
      display: block !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}
}


@media print {
  .head {
    -webkit-print-color-adjust: exact;
    background-color: #1a4567 !important;
  print-color-adjust: exact; 
  }
  }

  .custom-modal {
    display: none;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}
.custom-modal-dialog {
    position: relative;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
}
.custom-modal-content {
    background-color: #fff;
    border-radius: 0.3rem;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    padding: 1rem;
}